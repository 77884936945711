function handleFirstTab(e) {
  let key = e.key || e.keyCode;
  if (key === 'Tab' || key === '9') {
    $('body').removeClass('no-outline');

    window.removeEventListener('keydown', handleFirstTab);
    window.addEventListener('mousedown', handleMouseDownOnce);
  }
}

function handleMouseDownOnce() {
  $('body').addClass('no-outline');

  window.removeEventListener('mousedown', handleMouseDownOnce);
  window.addEventListener('keydown', handleFirstTab);
}

window.addEventListener('keydown', handleFirstTab);

(function ($) {
  $(document).ready(function () {
    $('.ada__btn').click(function () {
      $('.ada__widget-wrapper').fadeIn();
      return false;
    });

    $('.ada-screen-settings-list input[type=radio]').change(function () {
      // if ($(this).prop('checked')) {
      // } else {
      //   $(this).prop('checked', false);
      // }
      var value = $(this).val();
      $('html').attr('data-ada-screen', value);
    });

    $('.ada-font-size-settings input[type=radio]').change(function () {
      var value = $(this).val();
      $('html').attr('data-ada-fs', value);

      var coef = $(this).val();
      // Change coef
      document.documentElement.style.setProperty('--ada_fs_coef', coef);
      setTimeout(function () {
        $.fn.matchHeight._update();
      }, 500);
    });
    $('.ada-line-height-settings input[type=radio]').change(function () {
      var value = $(this).val();
      $('html').attr('data-ada-lh', value);
      var coef = $(this).val();
      // Change coef
      document.documentElement.style.setProperty('--ada_lh_coef', coef);

      setTimeout(function () {
        $.fn.matchHeight._update();
      }, 500);
    });

    $('#ada-readable-checkbox').change(function () {
      if ($(this).prop('checked')) {
        $('html').attr('data-font', 'readable');
      } else {
        $('html').attr('data-font', '');
      }
    });

    $('.ada__widget-body input').change(function () {
      var formData = JSON.stringify($('.ada__widget-body').serializeArray());

      if (formData) {
        setCookie('ada-settings', formData);
      } else {
        deleteCookie('ada-settings');
      }
    });

    $('.ada__widget-body input[type=checkbox]').change(function () {
      var value = $(this).val();

      var id = $(this).attr('id');
      if (id === 'ada-big-black-cursor-check') {
        $('#ada-big-white-cursor-check').prop('checked', false);
        $('body').removeClass('ada-big-white-cursor');
      }
      if (id === 'ada-big-white-cursor-check') {
        $('#ada-big-black-cursor-check').prop('checked', false);
        $('body').removeClass('ada-big-black-cursor');
      }

      $('body').toggleClass(value);
    });

    $('.ada__widget-close, .ada__widget-over').click(function () {
      $('.ada__widget-wrapper').fadeOut();
      return false;
    });
    fillForm();
  });

  function fillForm() {
    var ada_settings = getCookie('ada-settings');

    if (ada_settings) {
      var settings = JSON.parse(ada_settings);

      if (settings) {
        var settings_exists = false;
        settings.forEach(function (item) {
          if (item.value) {
            //empty or default value && item.value !== '1'
            $(
              '.ada__widget-body input[name="' +
                item.name +
                '"][value="' +
                item.value +
                '"]'
            ).prop('checked', true);
            settings_exists = true;
          }
        });
        settings.forEach(function (item) {
          if (item.value) {
            //empty or default value && !item.value !== '1'
            $(
              '.ada__widget-body input[name="' +
                item.name +
                '"][value="' +
                item.value +
                '"]'
            ).trigger('change');
          }
        });

        if (settings_exists) {
          $('.ada__btn').addClass('ada-has-settings');
        }
      }
    }
  }

  function getCookie(name) {
    let matches = document.cookie.match(
      new RegExp(
        '(?:^|; )' + name.replace(/([$?*|{}\]\\^])/g, '\\$1') + '=([^;]*)'
      )
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }
  function setCookie(name, value, options = {}) {
    options = {
      path: '/',
      ...options,
    };

    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }

    let updatedCookie =
      encodeURIComponent(name) + '=' + encodeURIComponent(value);
    for (let optionKey in options) {
      updatedCookie += '; ' + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += '=' + optionValue;
      }
    }
    document.cookie = updatedCookie;
  }
  function deleteCookie(name) {
    setCookie(name, '', {
      'max-age': -1,
    });
  }
})(jQuery);
