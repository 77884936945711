// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  $('.latest-news-slider .post-title').matchHeight();

  $('.staff-card').matchHeight();
  $('.staff-card__title').matchHeight();
  $('.staff-card__position').matchHeight();

  $('.form-section .gfield--type-html p').matchHeight();

  $('.current-posts .post-item .widget-title').matchHeight();

  $('.events-slide').matchHeight();

  // $('.header .primary-menu .has-dropdown a').on('click', function () {
  //   console.log($('.custom-submenu').hasClass('js-dropdown-active'));
  //   if ($('.custom-submenu').hasClass('js-dropdown-active')) {
  //     $('main').css('opacity', 0.93);
  //     $('footer').css('opacity', 0.93);
  //   } else {
  //     $('main').css('opacity', 1);
  //     $('footer').css('opacity', 1);
  //   }
  // });

  $('[data-dropdown-menu]').on('show.zf.dropdownMenu', function () {
    $('main').css('opacity', 0.1);
    $('footer').css('opacity', 0.1);
  });

  $('[data-dropdown-menu]').on('hide.zf.dropdownMenu', function () {
    $('main').css('opacity', 1);
    $('footer').css('opacity', 1);
  });

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  $('.search-icon').on('click', function () {
    $(this).next().toggle('show');
  });

  $('.text-slider').each(function () {
    let arrowPrev = $(this).parents('.text-slider-section').find('.prev-slide');
    let arrowNext = $(this).parents('.text-slider-section').find('.next-slide');
    $(this).slick({
      arrows: true,
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: arrowPrev,
      nextArrow: arrowNext,
    });
  });

  $('.testimonials-slider').each(function () {
    let arrowPrev = $(this)
      .parents('.testimonials-slider-section')
      .find('.prev-slide');
    let arrowNext = $(this)
      .parents('.testimonials-slider-section')
      .find('.next-slide');
    $(this).slick({
      arrows: true,
      dots: false,
      fade: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: arrowPrev,
      nextArrow: arrowNext,
    });
  });

  $('.events-slider').each(function () {
    let arrowPrev = $(this)
      .parents('.events-slider-section')
      .find('.prev-slide');
    let arrowNext = $(this)
      .parents('.events-slider-section')
      .find('.next-slide');
    let singleEventsSlider = $(this).next();

    $(this).slick({
      slidesToShow: 4,
      infinite: false,
      slidesToScroll: 1,
      arrows: true,
      asNavFor: singleEventsSlider,
      prevArrow: arrowPrev,
      nextArrow: arrowNext,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    singleEventsSlider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      arrows: false,
      asNavFor: $(this),
      fade: true,
      dots: false,
      centerMode: true,
      focusOnSelect: true,
    });
  });

  $('.events-slider .slick-active').each(function (index) {
    if (index == 0) {
      $(this).css('opacity', 1);
    } else if (index == 1) {
      $(this).css('opacity', 0.83);
    } else if (index > 1) {
      $(this).css('opacity', 0.54);
    }
  });

  $('.events-slider').on('afterChange', function () {
    $('.events-slider .slick-slide').each(function () {
      $(this).css('opacity', 0.17);
    });

    $('.events-slider .slick-active').each(function (index) {
      if (index == 0) {
        $(this).css('opacity', 1);
      } else if (index == 1) {
        $(this).css('opacity', 0.83);
      } else if (index > 1) {
        $(this).css('opacity', 0.54);
      }
    });
  });

  $('.latest-news-slider').each(function () {
    let arrowPrev = $(this).parents('.latest-news-section').find('.prev-slide');
    let arrowNext = $(this).parents('.latest-news-section').find('.next-slide');
    $(this).slick({
      arrows: true,
      dots: false,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      prevArrow: arrowPrev,
      nextArrow: arrowNext,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  });

  $('.latest-news-slider .slick-active').each(function (index) {
    if ($(window).width() > '500') {
      if (index == 0) {
        $(this).css('opacity', 0.72);
      } else if (index == 1) {
        $(this).css('opacity', 1);
      } else if (index == 2) {
        $(this).css('opacity', 0.72);
      }
    }
  });

  $('.latest-news-slider').on('afterChange', function () {
    if ($(window).width() > '500') {
      $('.latest-news-slider .slick-active').each(function (index) {
        if (index == 0) {
          $(this).css('opacity', 0.72);
        } else if (index == 1) {
          $(this).css('opacity', 1);
        } else if (index == 2) {
          $(this).css('opacity', 0.72);
        }
      });

      $('.latest-news-slider .post-title').matchHeight();

      $('.latest-news-slider .slick-slide').each(function () {
        if ($(this).hasClass('slick-active') == false) {
          $(this).css('opacity', 0.23);
        }
      });
    }

    $('.post-title').matchHeight();
  });

  let totalItems = $('#pagination').attr('data-count'); // total number of elements
  let itemsPerPage = 3; // number of elements per page
  let totalPages = Math.ceil(totalItems / itemsPerPage);

  // Determining the current page
  let currentPage = 1;

  // Function for drawing pagination
  function renderPagination(totalPages) {
    const paginationElement = $('#pagination');
    paginationElement.html('');

    const prevPage = $('<li>&lt;</li>').click(function () {
      if (currentPage > 1) {
        currentPage--;
        handlePageChange();
      }
    });
    paginationElement.append(prevPage);

    for (let i = 1; i <= totalPages; i++) {
      let li = $('<li></li>')
        .text(i)
        .click(function () {
          currentPage = i;
          handlePageChange();
        });

      if (i === currentPage) {
        li.addClass('active');
      }

      paginationElement.append(li);
    }

    const nextPage = $('<li>&gt;</li>').click(function () {
      if (currentPage < totalPages) {
        currentPage++;
        handlePageChange();
      }
    });
    paginationElement.append(nextPage);
  }

  // Function to highlight the current page
  function highlightCurrentPage() {
    $('#pagination li').removeClass('active');
    $('#pagination li').eq(currentPage).addClass('active');
  }

  renderPagination(totalPages);

  /* global ajax_object */

  // Function to handle page change
  function handlePageChange() {
    highlightCurrentPage();

    let year = $('select[name="year"]').find('option:selected').text();
    let pagination = $('#pagination');
    let page = $('#pagination li.active').text();

    $.ajax({
      url: ajax_object.ajax_url,
      data: {
        action: 'filter_year',
        year: year,
        paged: page,
      },
      type: 'post',
      success: function (result) {
        $('.posts-list').html(result.html);
        if (3 >= result.post_count) {
          pagination.hide();
        } else {
          pagination.show();
          renderPagination(result.postCount);
        }
        $('html, body').scrollTop($('.posts-list').offset().top - 200);
      },
      error: function (result) {
        console.warn(result);
      },
    });
  }

  $('select[name="year"]').on('change', function () {
    let year = $(this).find('option:selected').text();
    let pagination = $('#pagination');

    $.ajax({
      url: ajax_object.ajax_url,
      data: {
        action: 'filter_year',
        year: year,
      },
      type: 'post',
      success: function (result) {
        $('.posts-list').html(result.html);
        if (3 >= result.post_count) {
          pagination.hide();
        } else {
          pagination.show();
          renderPagination(result.postCount);
        }
      },
      error: function (result) {
        console.warn(result);
      },
    });
  });

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }

  $('.video-wrap .play-button').on('click', function () {
    $(this).fadeOut();
    let videoWrap = $(this).parents('.video-wrap');
    videoWrap.find('img').fadeOut();
    let iframe = videoWrap.find('iframe');
    let urlIframe = iframe.attr('src');
    iframe.attr('src', urlIframe.replace('?', '?autoplay=1&loop=1'));
  });

  $('iframe').on('click', function () {
    let videoWrap = $(this).parents('.video-wrap');
    videoWrap.find('img').fadeIn();
    let urlIframe = $(this).attr('src');
    $(this).attr('src', urlIframe.replace('?', '?autopause=0'));
  });
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
